/**
 * useValidators Composable
 *
 * Purpose:
 * This composable provides a set of common validation functions that can be reused across components.
 *
 * Functions:
 * - isEmail(email: string): Determines if the given string is a valid email format.
 *
 *
 * Usage Example:
 * ```javascript *
 *  const { isEmail } = useValidators();
 *  const valid = isEmail('test@example.com');
 * ```
 */

export function useValidators() {
  /**
   * Validates if the given string is a valid email format.
   *
   * @param {string} email - The email string to validate.
   * @returns {boolean} - Returns true if the email is valid, otherwise false.
   */
  function isEmail(email: string): boolean {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return re.test(email)
  }

  return { isEmail }
}
